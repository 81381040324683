/* eslint-disable */
// SRPClientSession must be used only once per login/register request
import { SRPClient } from './SRPClient'
import { AuthForm2Server } from '@/models/AuthFrom2Server.js'
import { SRPUser } from '@/models/SRPUser'
import Store from '../store'

function string2Bin(str) {
    let result = [];
    for (let i = 0; i < str.length; i++) {
        result.push(str.charCodeAt(i));
    }
    return result;
}

function generateRandomSalt() {
    let s = random16byteHex.random();
    return sha256((new Date()) + ':' + s);
}

var random16byteHex = (function () {

    function isWebCryptoAPI() {
        if (typeof(window) != 'undefined' && window.crypto && window.crypto.getRandomValues) {
            return true;
        }
        else if (typeof(window) != 'undefined' && window.msCrypto && window.msCrypto.getRandomValues) {
            return true;
        } else {
            return false;
        }
    };

    var crypto = isWebCryptoAPI();

    function seedIsaac() {
        if (crypto) return false;
        var value = +(new Date()) + ':' + Math.random();
        if (typeof(window) != 'undefined' && window.cookie) {
            value += document.cookie;
        }
        /*var h = CryptoJS.SHA256 || CryptoJS.SHA1;
        isaac.seed(h(value));*/
        return true;
    }

    var seeded = seedIsaac();

    function random() {
        var wordCount = 4;
        var randomWords;

        if (crypto) {
            var acrypto = window.crypto || window.msCrypto;
            randomWords = new Int32Array(wordCount);
            acrypto.getRandomValues(randomWords);
        } else {
            // skip forward an unpredictable amount
            var now = +(new Date());
            var t = now % 50;
            isaac.prng(1 + t);

            // grab some words
            randomWords = new Array();
            for (var i = 0; i < wordCount; i++) {
                randomWords.push(isaac.rand());
            }
        }

        var string = '';

        for (var i = 0; i < wordCount; i++) {
            var int32 = randomWords[i];
            if (int32 < 0) int32 = -1 * int32;
            string = string + int32.toString(16);
        }
        return string;
    };

    function nextBytes(arr) {
        let wordCount = arr.length;
        var randomWords;

        if (crypto) {
            var acrypto = window.crypto || window.msCrypto;
            randomWords = new Int32Array(wordCount);
            acrypto.getRandomValues(randomWords);
        } else {
            // skip forward an unpredictable amount
            var now = +(new Date());
            var t = now % 50;
            isaac.prng(1 + t);

            // grab some words
            randomWords = new Array();
            for (var i = 0; i < wordCount; i++) {
                randomWords.push(isaac.rand());
            }
        }

        for (var i = 0; i < wordCount; i++) {
            var int32 = randomWords[i];
            if (int32 < 0) int32 = -1 * int32;
            arr[i] = (int32 % 128);
        }

        return arr;
    };


    /**
     Run this within onkeyup of html inputs so that user typing makes the random numbers more random:
     random16byteHex.advance(Math.floor(event.keyCode/4));
     */
    function advance(ms) {
        if (!crypto) {
            var start = +(new Date());
            var end = start + ms;
            var now = +(new Date());
            while (now < end) {
                var t = now % 5;
                isaac.prng(1 + t);
                now = +(new Date());
            }
        }
    }

    return {
        'random': random,
        'isWebCryptoAPI': crypto,
        'advance': advance,
        'nextBytes': nextBytes
    };
})();

export class SRPClientSession {
    /**
     * Create new SRP Client
     */
    constructor() {
        let SRP6CryptoParams = Store.getters.getSRP6CryptoParams
        this.client = new SRPClient();
        this.client.init(SRP6CryptoParams.N, SRP6CryptoParams.g, SRP6CryptoParams.k);
    }

    /**
     * Create new User credentials
     * @param login {string}
     * @param password {string}
     * @return {SRPUser}
     */
    register(login, password, code) {
        let salt = string2Bin(generateRandomSalt());
        let verfier = this.client.generateVerifier(salt, login, password).toString(16);

        return new SRPUser(login, code, verfier, salt, sha256(password));
    }

    /**
     *  Create Client Credentials from Server challenge (s)
     * @param identity {string}
     * @param password {string}
     * @param s {string} salt
     * @return {AuthForm2Server}
     */
    stage1Login(identity, password, s) {
        let A = this.client.generateClientCredentials(s, identity, password);
        return new AuthForm2Server(A.toString(16), identity);
    }

    /**
     *  Stage2 calculates Secret and M1
     * @param B {BigInteger}
     */
    stage2(B) {
        this.client.calculateSecret(B);
        this.client.calculateClientEvidenceMessage();
    }

    /**
     *
     * @return {BigInteger}
     */
    calculateSessionKey() {
        return this.client.calculateSessionKey();
    }

    /**
     * M1
     * @return {BigInteger}
     */
    getM1() {
        return this.client.getM1();
    }

    /**
     * A
     * @return {BigInteger}
     */
    getA() {
        return this.client.A;
    }
}

