/* eslint-disable */
import { BigInteger } from '../modules/BigInteger'
import { sha256, sha224 } from 'js-sha256'
import { random16byteHex } from './random16byteHex'

function string2Bin(str) {
    let result = [];
    for (let i = 0; i < str.length; i++) {
        result.push(str.charCodeAt(i));
    }
    return result;
}
class Random {
    nextBytes(array) {
        return random16byteHex.nextBytes(array);
    }
}

let random = new Random();

function createRandomInRange(min, max) { // good
    let MAX_ITERATIONS = 1000;

    let cmp = min.compareTo(max);

    if (cmp >= 0) {
        if (cmp > 0) {
            throw error("'min' may not be greater than 'max'");
        }

        return min;
    }

    if (min.bitLength() > max.bitLength() / 2) {
        return createRandomInRange(BigInteger.ZERO, max.subtract(min)).add(min);
    }

    for (let i = 0; i < MAX_ITERATIONS; ++i) {
        let x = new BigInteger(max.bitLength(), random);
        if (x.compareTo(min) >= 0 && x.compareTo(max) <= 0) {
            return x;
        }
    }

    // fall back to a faster (restricted) method
    return new BigInteger(max.subtract(min).bitLength() - 1, random).add(min);
}

export class SRP6Util {
    /**
     * @param N {BigInteger}
     * @param g {BigInteger}
     * @return {BigInteger}
     */
    static calculateK(N, g) { // good
        return this.hashPaddedPair(N, N, g);
    }

    /**
     * @param N {BigInteger}
     * @param A {BigInteger}
     * @param B {BigInteger}
     * @return {BigInteger}
     */
    static calculateU(N, A, B) { // good
        return this.hashPaddedPair(N, A, B);
    }

    /**
     * @param N {BigInteger}
     * @param salt {Array}
     * @param identity {string}
     * @param password {string}
     * @return {BigInteger}
     */
    static calculateX(N, salt, identity, password) { // good
        let saltArr = salt;
        let idArr = string2Bin(identity);
        let pArr = string2Bin(password);

        let output = new Uint8Array(idArr.length + 1 + pArr.length);
        output.set(new Uint8Array(idArr));
        output.set(new Uint8Array([58]), idArr.length);
        output.set(new Uint8Array(pArr), idArr.length + 1);

        let first = sha256.array(output)

        output = new Uint8Array(saltArr.length + first.length);
        output.set(new Uint8Array(saltArr));
        output.set(new Uint8Array(first), saltArr.length);

        let digest = sha256(output);

        return new BigInteger(digest, 16);
    }

    /**
     * @param N {BigInteger}
     * @param g {BigInteger}
     * @return {BigInteger}
     */
    static generatePrivateValue(N, g) { // good
        let minBits = Math.min(256, N.bitLength() / 2);
        let min = BigInteger.ONE.shiftLeft(minBits - 1);
        let max = N.subtract(BigInteger.ONE);

        return createRandomInRange(min, max);
    }

    /**
     * @param N {BigInteger}
     * @param val {BigInteger}
     * @return {BigInteger}
     */
    static validatePublicValue(N, val) { // good
        val = val.mod(N);

        if (val.equals(BigInteger.ZERO)) {
            throw error("Invalid value: 0");
        }

        return val;
    }

    /**
     * Computes the client evidence message (M1) according to the standard routine:
     * M1 = H( A | B | S )
     * @param N {BigInteger} Modulus used to get the pad length
     * @param A {BigInteger} The client value
     * @param B {BigInteger} The server value
     * @param S {BigInteger} The secret calculated by both sides
     * @return {BigInteger} M1 The calculated client evidence message
     */
    static calculateM1(N, A, B, S) { // good
        let M1 = this.hashPaddedTriplet(N, A, B, S);
        return M1;
    }

    /**
     * Computes the server evidence message (M2) according to the standard routine:
     * M2 = H( A | M1 | S )
     * @param {BigInteger} N Modulus used to get the pad length
     * @param {BigInteger} A The client value
     * @param {BigInteger} M1 The client evidence message
     * @param {BigInteger} S The secret calculated by both sides
     * @return {BigInteger} M2 The calculated server evidence message
     */
    static calculateM2(N, A, M1, S) { // good
        let M2 = this.hashPaddedTriplet(N, A, M1, S);
        return M2;
    }

    /**
     * Computes the final Key according to the standard routine: Key = H(S)
     * @param {BigInteger} N Modulus used to get the pad length
     * @param {BigInteger} S The secret calculated by both sides
     * @return {BigInteger} the final Key value.
     */
    static calculateKey(N, S) { // good
        let padLength = (N.bitLength() + 7) / 8;
        let _S = this.getPadded(S, padLength);

        let output = sha256(_S);
        return new BigInteger(output, 16);
    }

    /**
     * @param N {BigInteger}
     * @param n1 {BigInteger}
     * @param n2 {BigInteger}
     * @param n3 {BigInteger}
     * @return {BigInteger}
     */
    static hashPaddedTriplet(N, n1, n2, n3) { // good
        let padLength = (N.bitLength() + 7) / 8;

        let n1_bytes = this.getPadded(n1, padLength);
        let n2_bytes = this.getPadded(n2, padLength);
        let n3_bytes = this.getPadded(n3, padLength);

        let output = new Uint8Array(n1_bytes.length + n2_bytes.length + n3_bytes.length);
        output.set(n1_bytes);
        output.set(n2_bytes, n1_bytes.length);
        output.set(n3_bytes, n2_bytes.length + n1_bytes.length);

        let digest = sha256(output);

        return new BigInteger(digest, 16);
    }

    /**
     * @param N {BigInteger}
     * @param n1 {BigInteger}
     * @param n2 {BigInteger}
     * @return {BigInteger}
     */
    static hashPaddedPair(N, n1, n2) { // good
        let padLength = (N.bitLength() + 7) / 8;

        let n1_bytes = this.getPadded(n1, padLength);
        let n2_bytes = this.getPadded(n2, padLength);

        let output = new Uint8Array(n1_bytes.length + n2_bytes.length);
        output.set(n1_bytes);
        output.set(n2_bytes, n1_bytes.length);

        let digest = sha256(output);

        return new BigInteger(digest, 16);
    }

    /**
     * @param n {BigInteger}
     * @param length {int}
     * @return {Uint8Array}
     */
    static getPadded(n, length) { // good
        let arr = n.toByteArray();
        if(arr[0] === 0) {
            arr.shift();
        }
        let bs = new Uint8Array(arr);

        if (bs.length < length) {
            let tmp = new Uint8Array(length);
            tmp.set(bs, length - bs.length);

            return tmp;
        } else {
            return bs;
        }
    }
}

