/* eslint-disable */
import { BigInteger } from '../modules/BigInteger'
import { SRP6Util } from '../modules/SRP6Util'
export class SRPClient {
    /**
     * Initialises the client to begin new authentication attempt
     * @param N {string}
     * @param g {string}
     * @param k {string}
     */
    init(N, g, k) {
        this.N = new BigInteger(N, 10);
        this.g = new BigInteger(g, 10);
        this.k = new BigInteger(k, 16);

        this.x = null;
        this.a = null;
        this.A = null;
        this.B = null;
        this.u = null;
        this.S = null;
        this.M1 = null;
        this.M2 = null;
        this.Key = null;
    }

    /**
     * Generates client's credentials given the client's salt, identity and password
     * @param salt {string}
     * @param identity {string}
     * @param password {string}
     * @return {BigInteger}
     */
    generateClientCredentials(salt, identity, password) { // good
        this.x = SRP6Util.calculateX(this.N, salt, identity, password);
        this.a = this.selectPrivateValue();
        this.A = this.g.modPow(this.a, this.N);
        return this.A
    }

    /**
     * Generates the secret S given the server's credentials
     * @param serverB {BigInteger} The server's credentials
     * @return {BigInteger} Client's verification message for the server
     */
    calculateSecret(serverB) { // good
        this.B = SRP6Util.validatePublicValue(this.N, serverB);
        this.u = SRP6Util.calculateU(this.N, this.A, this.B);
        this.S = this.calculateS();

        return this.S;
    }

    selectPrivateValue() { // good
        return SRP6Util.generatePrivateValue(this.N, this.g);
    }

    /**
     * @return {BigInteger}
     */
    calculateS() { // good
        this.k = SRP6Util.calculateK(this.N, this.g);
        let exp = this.u.multiply(this.x).add(this.a);
        let tmp = this.g.modPow(this.x, this.N).multiply(this.k).mod(this.N);

        return this.B.subtract(tmp).mod(this.N).modPow(exp, this.N);
    }

    /**
     * Computes the client evidence message M1 using the previously received values.
     * @return {string}
     */
    calculateClientEvidenceMessage() { // good
        // Verify pre-requirements
        if (this.A === null || this.B === null || this.S === null) {
            throw error("Impossible to compute M1: " +
                "some data are missing from the previous operations (A,B,S)");
        }
        // compute the client evidence message 'M1'
        this.M1 = SRP6Util.calculateM1(this.N, this.A, this.B, this.S);
        return this.M1;
    }

    /**
     * Authenticates the server evidence message M2 received and saves it only if correct.
     * @param {BigInteger} serverM2 the server side generated evidence message
     * @return {boolean} A boolean indicating if the server message M2 was the expected one.
     * @throws CryptoException
     */
    verifyServerEvidenceMessage(serverM2) { // good
        // Verify pre-requirements
        if (this.A === null || this.M1 === null || this.S === null) {
            throw error("Impossible to compute and verify M2: " +
                "some data are missing from the previous operations (A,M1,S)");
        }

        // Compute the own server evidence message 'M2'
        let computedM2 = SRP6Util.calculateM2(this.N, this.A, this.M1, this.S);
        if (computedM2.equals(serverM2)) {
            this.M2 = serverM2;
            return true;
        }
        return false;
    }

    /**
     * Computes the final session key as a result of the SRP successful mutual authentication
     * To be called after verifying the server evidence message M2.
     * @return {BigInteger} Key: the mutually authenticated symmetric session key
     * @throws CryptoException
     */
    calculateSessionKey() { // good
        // Verify pre-requirements (here we enforce a previous calculation of M1 and M2)
        if (this.S === null || this.M1 === null || this.M2 === null) {
            throw error("Impossible to compute Key: " +
                "some data are missing from the previous operations (S,M1,M2)");
        }
        this.Key = SRP6Util.calculateKey(this.N, this.S);
        return this.Key;
    }

//----------------------------------------------------------------------------------------------------------------------
    /**
     * @param {Array} salt
     * @param {string} identity
     * @param {string} password
     * @return {BigInteger}
     */
    generateVerifier(salt, identity, password) { // good
        let x = SRP6Util.calculateX(this.N, salt, identity, password);

        return this.g.modPow(x, this.N);
    };

    getM1() {
        return this.M1;
    }

    getM2() {
        return this.M2;
    }
}
